import { tns } from "tiny-slider";
window.tns = tns;

window.screen_xs = 480;
window.screen_xs_min = screen_xs;
window.screen_phone = screen_xs_min;
window.screen_sm = 768;
window.screen_sm_min = screen_sm;
window.screen_tablet = screen_sm_min;
window.screen_md = 992;
window.screen_md_min = screen_md;
window.screen_desktop = screen_md_min;
window.screen_lg = 1200;
window.screen_lg_min = screen_lg;
window.screen_lg_desktop = screen_lg_min;
window.screen_xs_max = (screen_sm_min - 1);
window.screen_sm_max = (screen_md_min - 1);
window.screen_md_max = (screen_lg_min - 1);

window.resizeTimer = null;
window.brandsSliderObj = {};
window.collectionsSliderobj = [];

window.get_vw = function() {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

window.get_vh = function() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

window.init_quantity_control = function () {
    $(document).on('click', '.quantity-control .plus, .quantity-control .minus', function () {
        var $input = $(this).closest('.quantity-control').find('input');
        if ($(this).parent().hasClass('disabled')) {
            alert($input.data('disabled-alert'));
            
            return false;
        } else {
            var val = parseInt($input.val()) || 1;

            if ($(this).is('.minus')) {
                val--;
            } else {
                val++;
            }

            var max_val = $input.data('max') !== undefined ? $input.data('max') : 999;
            var asort = $input.data('asort') !== undefined ? 1 : 0;

            if (val <= 0) val = 1;
            if (val > max_val) {
                if (!asort) {
                    val = max_val;
                }

                var title_text = asort == 1 ? $input.data('max-title-asort') : $input.data('max-title');

                if (!asort || max_val > 0) {
                    $(this).tooltip({ placement: "top", title: title_text });
                    $(this).tooltip('enable');
                    $(this).tooltip('show');
                    $(this).tooltip('disable');
                }
            }

            $input.val(val);
        }
    });
}

window.clearViewedProducts = function (){
    $(document).on('click', '#clear-viewed', function(e) {
        e.preventDefault();
        document.cookie = "recent_products=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        $('#recently-viewed-products').removeClass (function (index, className) {
            return (className.match (/(^|\s)d-\S+/g) || []).join(' ');
        }).fadeOut();
    });
}

window.init_authorize = function() {
    $(document).on('click', '.need2authorize', function(e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/customers/authorize',
            callback: function(html) {
                $("#dynamicModal").remove();
                $(".modal-backdrop").remove();
                doModal(html, 'modal-auth');
            }
        });
    });
    $(document).on('click', '.need2login', function(e) {
        if (typeof customer_session == 'undefined' || !customer_session) {
            e.preventDefault();
            $("#dynamicModal").remove();
            $(".modal-backdrop").remove();
            ajaxnav({
                url: window.location.href,
                template: 'content_types/customers/login',
                data: {
                    redirect: $(this).data('redirect')
                },
                callback: function(html) {
                    doModal(html, 'modal-auth');
                }
            });   
        }
    });
    $(document).on('click', '.need2prelogin', function(e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/customers/prelogin',
            callback: function(html) {
                doModal(html, 'modal-auth');
            }
        });
    });
    $(document).on('submit', '#continueLoggedIn', function(e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            method: 'POST',
            data: $(this).serialize(),
            template: 'content_types/customers/authorize',
            callback: function(data) {
                var error = false;
                try {
                    var res = $.parseJSON(data);
                    if (typeof res.error != 'undefined') {
                        error = res.error;
                    }
                } catch (e) {
                    
                }

                if (error) {
                    $('#continueLoggedIn .form-item').addClass('item-has-error');
                    $('#continueLoggedIn .form-item').append('<div class="form-item-error">'+ error +'</div>');
                } else {
                    doModal(data, 'modal-auth');
                }
            }
        });
    }); 
}

window.init_register = function() {
    $(document).on('click', '.need2register', function(e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/customers/register',
            callback: function(html) {
                doModal(html, 'modal-auth');
            }
        });
    });
}

window.initListingAddToCart = function() {
    $(document).on('click', '.add-to-cart.listing', function(e){
        e.preventDefault();
        var el = $(this);
        let data = { state: 'add2cart', item_id: el.data('id'), amount: 1 };

        ajaxnav({
            url: el.data('url'),
            data: data,
            method: 'POST',
            template: 'content_types/products/add2cart',
            callback: function(response) {
                var sel = $(response).filter('#script_add_to_cart');
                if(typeof(sel) != 'undefined') {
                    $(document.body).append(sel.html());
                }
                ajaxnav('', '#cartInfo', 'content_types/carts/cart_info', false);
                ajaxnav({
                    url: window.location.href,
                    data: data,
                    method: 'POST',
                    template: 'content_types/products/added2cart',
                    callback: function (html) {
                        doModal(html);
                    }
                });
            }
        });
    });
}

window.setRating = function (target, rating) {
    let parent = target.closest('.js-star-rating');
    target.closest('.your-ratio-wrp').find('#rating-input').val(rating);
    // fill all the stars assigning the '.selected' class
    parent.find('i').removeClass('fa-regular').addClass('fa-solid selected');
    // empty all the stars to the right of the mouse
    parent.find('i#rating-' + rating + ' ~ i').removeClass('fa-solid selected').addClass('fa-regular');
    parent.parent().find('.error').remove();
    parent.find('span').text(rating + '.0');
    parent.find('.rating-in-words > div').hide();
    parent.find('.rating-in-words .rating-' + rating).show();
    parent.parent().find('.rating-in-words > div').hide();
    parent.parent().find('.rating-in-words .rating-' + rating).show();
}

window.initModColorsTooltips = function (){
    if (get_vw() > screen_md) {
        $('.mod-color-item .mod-color').each(function () {
            new bootstrap.Tooltip(this, {
                title: $(this).data('title'),
                trigger: 'hover',
                placement: 'bottom',
                customClass: 'colors-tooltip'
            });
        });
    }
}

window.initBrandsSlider = function () {
    let vw = get_vw();
    let brandsList = $('.brands-list');
    let options = {
        container: brandsList[0],
        items: 4,
        slideBy: 3,
        autoplayTimeout: 6000,
        speed: 2000,
        autoplay: true,
        autoplayButtonOutput: false,
        nav: false,
        controls: false,
        center: true,
        fixedWidth: 100
    };

    if (vw < screen_md && brandsList.length && typeof brandsSliderObj.isOn === 'undefined') {
        brandsSliderObj = tns(options);
    } else if (vw < screen_md && brandsList.length && brandsSliderObj.isOn === null) {
        brandsSliderObj = brandsSliderObj.rebuild();
    } else if (vw > screen_md && brandsSliderObj.isOn) {
        brandsSliderObj.destroy();
    }
}

window.initCollectionSlider = function (container, options_type) {
    var options_type = typeof options_type !== 'undefined' ? options_type : 'default';
    var singleCollSliderObj = {};

    if(options_type == 'compact'){
        var options = {
            autoplay: false,
            loop: false,
            gutter: 0,
            autoplayButtonOutput: false,
            nav: false,
            controls: true,
            controlsText: ['<i class="fa-regular fa-angle-left"></i>', '<i class="fa-regular fa-angle-right"></i>'],
            responsive: {
                992: {
                    items: 4
                },
                1400: {
                    items: 6
                }
            }
        };
    }else if(options_type == 'default'){
        var options = {
            autoplay: false,
            loop: false,
            gutter: 30,
            autoplayButtonOutput: false,
            nav: false,
            controls: true,
            controlsText: ['<i class="fa-regular fa-angle-left"></i>', '<i class="fa-regular fa-angle-right"></i>'],
            responsive: {
                768: {
                    fixedWidth: 200
                },
                992: {
                    fixedWidth: 250
                },
                1400: {
                    fixedWidth: 300
                },
                1810: {
                    fixedWidth: 396
                }
            }
        };
    }

    if (get_vw() > screen_md && $(container).length && typeof singleCollSliderObj.isOn === 'undefined') {
        singleCollSliderObj = tns(Object.assign({}, options, {container: container}));
    } else if (get_vw() > screen_md && $(container).length && singleCollSliderObj.isOn === null) {
        singleCollSliderObj = singleCollSliderObj.rebuild();
    } else if (get_vw() < screen_md && singleCollSliderObj.isOn) {
        singleCollSliderObj.destroy();
    }
}

window.buyTogetherItems = function () {
    let currencySymbol = $('#buy-together-items').data('currency-symbol');
    let price = 0;
    let oldPrice = 0;
    let not_logged_price = 0;
    let logged_price = 0;

    $('#buy-together-items .together-item.active').each(function () {
        price += parseFloat($(this).data('price'));
        oldPrice += parseFloat($(this).data('old-price'));
        not_logged_price += parseFloat($(this).data('not-logged-price'));
        logged_price += parseFloat($(this).data('logged-price'));
    });

    $('#buy-together-summary .price').text((price ? price.toFixed(2).replace('.', ',') : 0) + ' ' + currencySymbol);
    if (logged_price < price) {
        $('#buy-together-summary .price-logged').show();
        $('#buy-together-summary .for-members').show();
        $('#buy-together-summary .price').removeClass('main');
        $('#buy-together-summary .price-old').removeClass('main');
    } else {
        $('#buy-together-summary .price-logged').hide();
        $('#buy-together-summary .for-members').hide();
        $('#buy-together-summary .price').addClass('main');
        $('#buy-together-summary .price-old').addClass('main');
    }
    
    $('#buy-together-summary .price-logged > span').text((logged_price ? logged_price.toFixed(2).replace('.', ',') : 0) + ' ' + currencySymbol);
    $('#buy-together-items .cta-btn').attr('disabled', price === 0);
    
    if (oldPrice > price) {
        $('#buy-together-summary .price-old').text(oldPrice.toFixed(2).replace('.', ',') + ' ' + currencySymbol);
        $('#buy-together-summary .price-old').show();
    } else {
        $('#buy-together-summary .price-old').hide();
    }
}

window.update_wishlist_info = function() {
    $.ajax({
        url: '?display=content_types/wishlist/wishlist_info',
        method: 'post',
        success: function(html) {
            $('.wishlistInfo').replaceWith(html);
        }
    });
}

window.doModal = function(content, dialogClasses) {
    dialogClasses = typeof dialogClasses !== 'undefined' ? dialogClasses : 'modal-lg';
    let dialogCentered = get_vw() >= screen_sm ? 'modal-dialog-centered' : '';
    let html =  '<div class="modal fade" id="dynamicModal" tabindex="-1" role="dialog">';
    html += '<div class="modal-dialog '+dialogClasses+' modal-fullscreen-sm-down modal-dialog-scrollable '+dialogCentered+'" role="document">';
    html += '<div class="modal-content">';
    html += '<button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"><i class="fa-regular fa-xmark"></i></button>';
    html += '<div class="modal-body">';
    html += content;
    html += '</div>'; 	// body
    html += '</div>';  	// content
    html += '</div>';  	// dialog
    html += '</div>';  	// modal
    $('body').append(html);
    $("#dynamicModal").modal();
    $("#dynamicModal").modal('show');
    $('#dynamicModal').on('hidden.bs.modal', function () {
        $(this).remove();
    });
}

window.rotateMenuIcon = function(x) {
    x.classList.toggle("change");
}

window.products_empty_scroll_top = function(x) {
    if($(window).width() < 992) return;
    scrollToElement('#products-listing');
}

window.filter_scroll_notice = function(x) {
    let notice = $('.filter-scroll-notice');
    if(!notice.length) return;
    let wrp = $('.products-list-wrp');
    let wrpH = wrp.outerHeight();
    let inner_el_height = 0;
    let opacity = 0;
    let visibility = 'hidden';

    $('.products-list-wrp > *:not(.filter-scroll-notice)').each(function () {
        inner_el_height += $(this).outerHeight(true);
    });

    if((wrpH - inner_el_height) < 200 && $(window).width() < 992) return;

    let wrp_pos = wrp.offset();
    let notice_pos = notice.offset().top + notice.outerHeight();
    let notice_left_offset = wrp_pos.left + (wrp.width() / 2) - (notice.width() / 2);
    let scroll_from = wrp_pos.top + inner_el_height;
    let scroll_to = wrp_pos.top + wrpH;

    if((notice_pos - scroll_from - 80) > 0 && notice_pos < scroll_to){
        opacity = 1;
        visibility = 'visible';
    }else if((notice_pos - scroll_to) > 0){
        opacity = 0;
        visibility = 'hidden';
    }else if((notice_pos - scroll_from - 80) < 0){
        opacity = 0;
        visibility = 'hidden';
    }

    notice.css({
        'opacity': opacity,
        'visibility': visibility,
        'left': notice_left_offset + 'px'
    });
}

window.scrollToElement = function (el) {
    if(!$(el).length) return;

    let mainNavH = $('.main-nav').outerHeight();
    if(typeof mainNavH == 'undefined') mainNavH = 0;

    $('html, body').stop().animate({
        'scrollTop': $(el).offset().top - $('[data-scroll-header]').outerHeight() - mainNavH
    }, 500, 'swing');
}

window.update_cart_info = function (){
    ajaxnav('', '#cartInfo', 'content_types/carts/cart_info', false);
}

window.tabPaneMobileModal = function (el) {
    let target = $(el).data('bs-target');
    if (typeof target === 'undefined') target = $(el).data('target');
    if (typeof target === 'undefined') return;
    let tabContent = $(target).html();

    $(target).empty();

    doModal(tabContent, 'tab-panel-modal');

    $('#dynamicModal').on('hidden.bs.modal', function () {
        $(target).html(tabContent);
    });
}

window.ajaxnav = function(params) {
    if (params.url && params.url.indexOf("#") !== -1){
        params.url = params.url.substring(0, params.url.indexOf('#'));
    }
    // ajaxnav(url, container, template, update_address, append_history)
    if (typeof params !== 'object') {
        params = {
            url: arguments[0],
            container: arguments[1],
            template: arguments[2],
            update_address: arguments[3] !== undefined ? arguments[3] : true,
            append_history: arguments[4] !== undefined ? arguments[4] : true,
        }
    }

    // uzpidom default'iniais parametrais
    params = $.extend({
        update_address: true,
        append_history: true,
        method: 'GET',
        data: {},
        error: function(data, textStatus, jqXHR) {
            $('#ajax_loader').hide();
            if (params.callback !== undefined) {
                params.callback(data, textStatus, jqXHR);
            }
        },
        success: function(data, textStatus, jqXHR) {
            $('#ajax_loader').hide();
            if (typeof data == 'object') {
                if (data.reload === true) {
                    $('#ajax_loader').show();
                    window.location.href = data.url;
                } else {
                    ajaxnav($.extend({
                        container: params.container,
                        template: params.template,
                        update_address: params.update_address,
                        append_history: params.append_history,
                        callback: params.callback
                    }, data));
                }
            } else if (typeof data == 'string') {
                if (params.container !== undefined) {
                    var $data = $(data);

                    if (!$data.attr('id')) {
                        $data.attr('id', 'id-' + Math.random().toString(36).substr(2, 16));
                    }
                    var $previous = $(params.container).replaceWith($data);

                    if (params.update_address) {
                        var pso = {
                            template: params.template,
                            container: params.container
                        }
                        var final_url = jqXHR.getResponseHeader('X-AJAXNAV-URL');
                        if (!final_url) final_url = params.url;
                        if (params.append_history) {
                            // window.history.replaceState(pso, '', window.location);
                            window.history.pushState(pso,'', final_url);
                        } else {
                            window.history.replaceState(pso,'', final_url);
                        }
                    }
                }
                if (params.callback !== undefined) {
                    params.callback(data, textStatus, jqXHR);
                }
            }
        }
    }, params);

    // vykdom ajax request'a
    $.ajax({
        url: params.url + (!params.url.match(/\?/) ? '?' : (!params.url.match(/&$/) ? '&' : '')) + 'display=' + params.template,
        method: params.method,
        data: params.data,
        success: params.success,
        beforeSend: function() {
            $('#ajax_loader').show();
        },
    });
}

window.toLatin = function(str) {
    let from = [
        '¹','²','³','°','æ','ǽ','À','Á','Â','Ã','Å','Ǻ','Ă','Ǎ','Æ','Ǽ','à','á','â','ã','å','ǻ','ă','ǎ','ª','@','Ĉ','Ċ','ĉ','ċ','©','Ð','Đ','ð','đ','È','É',
        'Ê','Ë','Ĕ','Ė','è','é','ê','ë','ĕ','ė','ƒ','Ĝ','Ġ','ĝ','ġ','Ĥ','Ħ','ĥ','ħ','Ì','Í','Î','Ï','Ĩ','Ĭ','Ǐ','Į','Ĳ','ì','í','î','ï','ĩ','ĭ','ǐ','į',
        'ĳ','Ĵ','ĵ','Ĺ','Ľ','Ŀ','ĺ','ľ','ŀ','Ñ','ñ','ŉ','Ò','Ô','Õ','Ō','Ŏ','Ǒ','Ő','Ơ','Ø','Ǿ','Œ','ò','ô','õ','ō','ŏ','ǒ','ő','ơ','ø','ǿ','º','œ','Ŕ','Ŗ','ŕ','ŗ','Ŝ','Ș','ŝ','ș',
        'ſ','Ţ','Ț','Ŧ','Þ','ţ','ț','ŧ','þ','Ù','Ú','Û','Ũ','Ŭ','Ű','Ų','Ư','Ǔ','Ǖ','Ǘ','Ǚ','Ǜ','ù','ú','û','ũ','ŭ','ű','ų','ư','ǔ','ǖ','ǘ','ǚ','ǜ','Ŵ','ŵ','Ý','Ÿ','Ŷ','ý','ÿ','ŷ',
        'Ъ','Ь','А','Б','Ц','Ч','Д','Е','Ё','Э','Ф','Г','Х','И','Й','Я','Ю','К','Л','М','Н','О','П','Р','С','Ш','Щ','Т','У','В','Ы','З','Ж','ъ','ь','а','б','ц','ч','д','е','ё','э',
        'ф','г','х','и','й','я','ю','к','л','м','н','о','п','р','с','ш','щ','т','у','в','ы','з','ж', 'Ä','Ö','Ü','ß','ä','ö','ü','Ç','Ğ','İ','Ş','ç','ğ','ı','ş',
        'Ā','Ē','Ģ','Ī','Ķ','Ļ','Ņ','Ū','ā','ē','ģ','ī','ķ','ļ','ņ','ū','Ґ','І','Ї','Є','ґ','і','ї','є','Č','Ď','Ě','Ň','Ř','Š','Ť','Ů','Ž','č','ď','ě','ň','ř','š','ť','ů','ž',
        'Ą','Ć','Ę','Ł','Ń','Ó','Ś','Ź','Ż','ą','ć','ę','ł','ń','ó','ś','ź','ż',
    ];


    let to = [
        1,2,3,0,'ae','ae','A','A','A','A','A','A','A','A','AE','AE','a','a','a','a','a','a','a','a','a','at','C','C','c','c','c','Dj','D','dj','d','E','E',
        'E','E','E','E','e','e','e','e','e','e','f','G','G','g','g','H','H','h','h','I','I','I','I','I','I','I','I','IJ','i','i','i','i','i','i','i','i',
        'ij','J','j','L','L','L','l','l','l','N','n','n','O','O','O','O','O','O','O','O','O','O','OE','o','o','o','o','o','o','o','o','o','o','o','oe','R','R','r','r','S','S','s','s',
        's','T','T','T','TH','t','t','t','th','U','U','U','U','U','U','U','U','U','U','U','U','U','u','u','u','u','u','u','u','u','u','u','u','u','u','W','w','Y','Y','Y','y','y','y',
        '','','A','B','C','Ch','D','E','E','E','F','G','H','I','J','Ja','Ju','K','L','M','N','O','P','R','S','Sh','Shch','T','U','V','Y','Z','Zh','','','a','b','c','ch','d','e','e',
        'e','f','g','h','i','j','ja','ju','k','l','m','n','o','p','r','s','sh','shch','t','u','v','y','z','zh','AE','OE','UE','ss','ae','oe','ue','C','G','I','S','c','g','i','s',
        'A','E','G','I','K','L','N','U','a','e','g','i','k','l','n','u','G','I','Ji','Ye','g','i','ji','ye','C','D','E','N','R','S','T','U','Z','c','d','e','n','r','s','t','u','z',
        'A','C','E','L','N','O','S','Z','Z','a','c','e','l','n','o','s','z','z',
    ];

    for (let key in from) {
        str = str.replace(new RegExp(from[key], 'g'), to[key])
    }

    return str;
}

window.enhancedEcomm = function(type, products, order_info, step) {
    window.dataLayer = window.dataLayer || [];
    var ecomm = null;
    var $allowedTypes = [];//['detail', 'addToCart', 'removeFromCart', 'checkout', 'purchase'];
    if($allowedTypes.indexOf(type) === -1) {
        return false;
    }

    if (type === 'detail') {
        ecomm = {
            detail: {
                products: products
            }
        };
    }
    if (type === 'addToCart') {
        ecomm = {
            currencyCode: 'EUR',
            add: {
                products: products
            }
        };
    }
    if (type === 'removeFromCart') {
        ecomm = {
            remove: {
                products: products
            }
        };
    }
    if (type === 'checkout') {
        ecomm = {
            checkout: {
                actionField: {
                    'step': step
                },
                products: products
            }
        };
    }
    if (type === 'purchase') {
        type = 'ecomm_purchase';
        ecomm = {
            purchase: {
                actionField: order_info,
                products: products
            }
        };
    }

    window.dataLayer.push({
        event: type,
        ecommerce: ecomm
    });
}

window.toggleProfileMenu = function() {
    if ($('#profile-dropdown').hasClass('active')) {
       var uri = document.location.search;
        uri = uri.replace(/pm=/, "");
        var url = window.location.href.replace(/\?.*$/, '') + uri;
        window.history.pushState({}, '', url);
    }
    $('#profile-dropdown').toggleClass('active');
    $('.profile-item > a').toggleClass('active');
}